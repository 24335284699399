import { AlertTriangle, Info } from 'lucide-react';
import { Button } from './button';
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from './popover';
import {
  TooltipProvider,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipArrow,
  TooltipPortal
} from './tooltip';
import { ReactNode } from 'react';
import { cn } from 'lib/utils';
import useDocument from 'hooks/useDocument';

export type HybridTooltipType =
  | 'default'
  | 'secondary'
  | 'approved'
  | 'review'
  | 'changes'
  | 'information'
  | 'warning'
  | 'error'
  | 'Pinnacle'
  | 'Premier'
  | 'lic_portability'
  | 'sovereign_cloud'
  | 'not_applied';

interface PropTypes {
  text: ReactNode;
  type?: HybridTooltipType;
  delayDuration?: number;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  align?: 'center' | 'end' | 'start';
  alignOffset?: number;
  size?: 'sm' | 'md' | 'lg';
  asChild?: boolean;
  children?: ReactNode;
  contentClassName?: string;
  customColor?: string;
  alignAsText?: boolean;
  portalContainerId?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const sizes = {
  sm: 'w-4 h-4',
  md: 'w-5 h-5',
  lg: 'w-6 h-6'
};

const Icons = {
  default: ({ className }: { className?: string }) => (
    <Info className={`text-[#2360a0] ${className}`} />
  ),
  information: ({ className }: { className?: string }) => (
    <Info className={`text-[#1F384C] ${className}`} />
  ),
  secondary: ({ className }: { className?: string }) => (
    <Info className={`text-[#fff] ${className}`} />
  ),
  approved: ({ className }: { className?: string }) => (
    <Info className={`text-[#6fcf97] ${className}`} />
  ),
  review: ({ className }: { className?: string }) => (
    <Info className={`text-[#f0b51a] ${className}`} />
  ),
  changes: ({ className }: { className?: string }) => (
    <Info className={`text-[#CC2130] ${className}`} />
  ),
  onboarding: ({ className }: { className?: string }) => (
    <Info className={`text-[#C54600] ${className}`} />
  ),
  warning: ({ className }: { className?: string }) => (
    <AlertTriangle className={`text-[#f0b51a] ${className}`} />
  ),
  error: ({ className }: { className?: string }) => (
    <AlertTriangle className={`text-[#CC2130] ${className}`} />
  ),
  Pinnacle: ({ className }: { className?: string }) => (
    <Info className={`text-[#2360a0] ${className}`} />
  ),
  Premier: ({ className }: { className?: string }) => (
    <Info className={`text-[#528316] ${className}`} />
  ),
  lic_portability: ({ className }: { className?: string }) => (
    <Info className={`text-[#9149DA] ${className}`} />
  )
};

export default function HybridTooltip({
  text,
  type = 'default',
  size = 'md',
  side = undefined,
  sideOffset = undefined,
  align = undefined,
  alignOffset = undefined,
  asChild = false,
  children = null,
  contentClassName = '',
  customColor = '',
  alignAsText = false,
  portalContainerId = undefined,
  open,
  onOpenChange
}: PropTypes) {
  const Colors = {
    default: '#2360a0',
    information: '#2360a0',
    approved: '#6fcf97',
    review: '#f0b51a',
    changes: '#CC2130',
    warning: '#f0b51a',
    error: '#CC2130',
    onboarding: '#6fcf97',
    secondary: '#1F384C',
    Premier: '#528316',
    Pinnacle: '#2360a0',
    lic_portability: '#9149DA'
  };

  const Icon = Icons[type];
  const color = customColor ? customColor : Colors[type] || Colors['default'];

  const contentStyling = {
    className: cn(
      'box-border text-[0.8rem] font-normal text-white p-4 rounded-sm leading-5 max-w-[80vw] whitespace-normal normal-case',
      contentClassName
    ),
    style: { background: color }
  };

  const classSize = sizes[size];
  const document = useDocument();

  const portalContainer = portalContainerId
    ? document?.getElementById(portalContainerId)
    : undefined;

  return (
    <>
      <div
        className={cn(
          'hidden pb-[2px]',
          alignAsText ? 'sm:inline-flex align-middle pl-1' : 'sm:flex'
        )}
      >
        <TooltipProvider delayDuration={0} skipDelayDuration={0}>
          <Tooltip open={open} onOpenChange={onOpenChange}>
            <TooltipTrigger asChild>
              {asChild ? (
                children
              ) : (
                <Button
                  aria-label="Mobile Tooltip Trigger"
                  variant="ghost"
                  size="unstyled"
                  className="!m-0 hover:bg-transparent cursor-auto"
                >
                  <Icon className={classSize} />
                </Button>
              )}
            </TooltipTrigger>
            <TooltipPortal container={portalContainer}>
              <TooltipContent
                {...contentStyling}
                side={side}
                sideOffset={sideOffset}
                align={align}
                alignOffset={alignOffset}
              >
                {text}
                <TooltipArrow style={{ fill: color }} />
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div
        className={cn(
          'sm:hidden max-w-[95vw]',
          alignAsText ? 'inline-flex align-middle pl-1' : 'flex'
        )}
      >
        <Popover open={open} onOpenChange={onOpenChange}>
          <PopoverTrigger asChild>
            {asChild ? (
              children
            ) : (
              <Button
                aria-label="Desktop Tooltip Trigger"
                variant="ghost"
                size="unstyled"
                className="!m-0 hover:bg-transparent"
              >
                <Icon className={classSize} />
              </Button>
            )}
          </PopoverTrigger>
          <PopoverContent
            side="top"
            noPortal
            {...contentStyling}
            sideOffset={sideOffset}
            align={align}
            alignOffset={alignOffset}
          >
            {text}
            <PopoverArrow style={{ fill: color }} />
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
}
