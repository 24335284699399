import styles from './style.module.scss';

export default function ModerationButtons({
  id,
  onChangeApprove,
  approved,
  dismissed,
  smallButtons = false,
  gridColumn = false
}: {
  id: string;
  onChangeApprove: (id: string, type: number) => void;
  approved: boolean;
  dismissed: boolean;
  smallButtons?: boolean;
  gridColumn?: boolean;
}) {
  return (
    <div className={`${styles.container} ${gridColumn ? styles.column : ''}`}>
      <button
        className={`${styles.accept} ${dismissed ? styles.available : ''} ${
          smallButtons ? styles.resumable : ''
        }`}
        disabled={approved}
        onClick={() => onChangeApprove(id, 1)}
      >
        Accept
      </button>
      <button
        className={`${styles.dismiss} ${approved ? styles.available : ''} ${
          smallButtons ? styles.resumable : ''
        }`}
        disabled={dismissed}
        onClick={() => onChangeApprove(id, 0)}
      >
        Dismiss
      </button>
    </div>
  );
}
