import { useEffect, useState } from 'react';
import Select from 'react-select';
import styles from './style.module.scss';
import ChangesLog from '../ChangesLog';
import { useTranslation } from 'react-i18next';

export default function SelectMultiLog({
  id,
  value,
  instanceId,
  prevValue,
  data,
  onChange,
  placeholder,
  customStyles,
  closeMenuOnSelect,
  wasChanged,
  disabled,
  approved,
  changed,
  dismiss,
  onMenuOpen,
  onMenuClose,
  menuPlacement,
  maxValues,
  onError,
  error,
  customErrorMessage,
  hasCustomError = false
}) {
  const { t } = useTranslation();
  const [showLog, setShowLog] = useState(false);

  const approvedProps = { bdWidth: '2px', bdColor: '#6fcf97' };
  const dismissProps = { bdWidth: '2px', bdColor: '#CC2130' };
  const changedProps = { bdWidth: '2px', bdColor: '#f2c94c' };
  const requiredReviewProps = {
    bdWidth: '2px',
    bdColor: '#f2c94c',
    bg: '#f0b51a33'
  };

  const colors = wasChanged
    ? approved
      ? approvedProps
      : dismiss
      ? dismissProps
      : changed
      ? changedProps
      : requiredReviewProps
    : approved
    ? approvedProps
    : dismiss
    ? dismissProps
    : { bdWidth: '1px', bdColor: '#e6e6e6' };

  const maxValuesError =
    maxValues && maxValues > 0
      ? value &&
        Array.isArray(value) &&
        value.length > 0 &&
        value.length > maxValues
        ? true
        : false
      : false;

  const hasError = maxValuesError;

  useEffect(() => {
    onError && onError(hasError, id);
  }, [JSON.stringify(value), hasError]); //running every time value changes

  return (
    <>
      <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
        <Select
          id={id}
          key={id}
          isDisabled={disabled}
          instanceId={instanceId}
          inputId={instanceId}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          menuPlacement={menuPlacement}
          isMulti
          styles={{
            option: (provided, state) => ({
              ...provided,
              color: state.value === 'select-all' ? '#cc2031' : provided.color,
              fontSize: '14px'
            }),
            control: (styles, state) => ({
              ...styles,
              boxShadow: state.isFocused ? 'inset 0 0 0 1px #005c8a' : 0,
              backgroundColor: error
                ? '#f6dcdc'
                : hasError
                ? 'white'
                : colors.bg || 'white',
              borderColor: error
                ? '#cc092f'
                : hasError
                ? '#EB5757'
                : state.isFocused
                ? '#005c8a'
                : colors.bdColor,
              borderWidth: state.isFocused ? '1px' : colors.bdWidth,
              '&:hover': {
                borderColor: error ? '#cc092f' : '#005c8a'
              },
              minHeight: '54px'
            }),
            placeholder: (styles) => ({
              ...styles,
              fontWeight: '200'
            }),
            ...customStyles,
            multiValue: (provided, state) => {
              const previous = customStyles?.multiValue(provided, state) || {};

              return {
                ...provided,
                ...previous,
                backgroundColor: hasError
                  ? '#EC9C9D'
                  : previous.backgroundColor
                  ? previous.backgroundColor
                  : provided.backgroundColor,
                color: hasError
                  ? '#1F384C'
                  : previous.color
                  ? previous.color
                  : provided.color
              };
            },
            multiValueLabel: (provided, state) => {
              const previous =
                customStyles?.multiValueLabel(provided, state) || {};

              return {
                ...provided,
                ...previous,
                color: hasError
                  ? '#1F384C'
                  : previous.color
                  ? previous.color
                  : provided.color
              };
            }
          }}
          placeholder={placeholder}
          closeMenuOnSelect={closeMenuOnSelect}
          options={data}
          onChange={onChange}
          value={value}
        />

        {wasChanged && prevValue && (
          <ChangesLog
            className="absolute top-[50%] translate-y-[-50%] right-[80px]"
            showLog={showLog}
            setShowLog={setShowLog}
            prevValue={prevValue}
          />
        )}
      </div>

      {error && !hasCustomError && (
        <span className={styles.error}>Required field</span>
      )}
      {hasError && !hasCustomError && (
        <span className="my-1.5 absolute text-sm font-normal text-[#CC092F]">
          {maxValuesError ? t('max_select_values', { max: maxValues }) : null}
        </span>
      )}
      {hasCustomError && (
        <span className="my-1.5 absolute text-sm font-normal text-[#CC092F]">
          {customErrorMessage}
        </span>
      )}
    </>
  );
}
