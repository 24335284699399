import { useEffect, useState } from 'react';

export default function useDocument() {
  const [mounted, setMounted] = useState(false);
  const _doc = mounted ? document : null;

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return _doc;
}
