import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'lib/utils';
import {
  AlignJustify,
  ArrowLeft,
  ArrowRight,
  Bookmark,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Download,
  Eye,
  EyeOff,
  FolderInput,
  LayoutGrid,
  Loader2,
  Mail,
  PenLine,
  Plus,
  Save,
  Share2,
  Trash2,
  X
} from 'lucide-react';
import { MdOutlineHistory } from 'react-icons/md';
import { FiSend } from 'react-icons/fi';
import { forwardRef } from 'react';
import { AiOutlineLike } from 'react-icons/ai';
import { BiSolidLike } from 'react-icons/bi';

const buttonVariants = cva(
  'box-border group cursor-pointer inline-flex items-center justify-center gap-2 rounded-[3px] text-base tracking-[1px] font-light transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:cursor-not-allowed border-0',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground hover:bg-primary-hover disabled:bg-[#97999b]',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90 disabled:bg-[#97999b]',
        outline:
          'border border-solid border-input bg-background hover:bg-accent hover:text-accent-foreground disabled:bg-[#97999b]',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80 shadow-button disabled:bg-[#97999b]',
        navigation:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80 shadow-button disabled:bg-[#97999b] justify-between px-4 font-100',
        ghost:
          'bg-transparent text-[#1F384C] hover:bg-accent hover:text-primary-hover',
        link: 'bg-transparent text-primary underline-offset-4 hover:underline hover:text-primary-hover',
        unstyled: 'bg-transparent',
        special: 'bg-[#1f384c] text-white disabled:bg-[#97999b]',
        tab: cn(
          'relative flex-shrink-0 list-none rounded-b-none !h-auto !py-4 !px-8 m-0 text-base no-underline cursor-pointer bg-transparent text-[#b5bfc9] hover:text-black',
          'aria-selected:cursor-default aria-selected:bg-white aria-selected:rounded-t-md aria-selected:text-black',
          "aria-selected:after:content-[''] aria-selected:after:absolute aria-selected:after:bottom-[16px] aria-selected:after:left-0 aria-selected:after:w-[calc(100%-4rem)] aria-selected:after:ml-8 aria-selected:after:bg-[#cc092f] aria-selected:after:h-[3px]"
        )
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 px-3',
        lg: 'h-11 px-8',
        icon: 'h-10 w-10',
        unstyled: 'h-auto w-auto p-0'
      },
      theme: {
        default: ''
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      theme: 'default'
    }
  }
);

type Icons =
  | 'download'
  | 'subscribe'
  | 'add'
  | 'delivered'
  | 'special_save'
  | 'save'
  | 'history'
  | 'grid'
  | 'list'
  | 'trash'
  | 'send'
  | 'close'
  | 'edit'
  | 'import'
  | 'arrow-up'
  | 'left-arrow'
  | 'right-arrow'
  | 'chevron-left'
  | 'chevron-right'
  | 'share'
  | 'like-empty'
  | 'like-filled'
  | 'bookmark'
  | 'eye'
  | 'eye-off';

const getIcon = (type, className = '') => {
  const style = 'w-4 h-4';

  if (type === 'download') {
    return <Download className={cn(style, className)} />;
  } else if (type === 'subscribe') {
    return <Mail className={cn(style, className)} />;
  } else if (type === 'add') {
    return <Plus className={cn(style, className)} />;
  } else if (type === 'delivered') {
    return <CheckCircle className={cn(style, className)} />;
  } else if (['special_save', 'save'].includes(type)) {
    return <Save className={cn(style, className)} />;
  } else if (type === 'history') {
    return <MdOutlineHistory className={cn('w-[22px] h-[22px]', className)} />;
  } else if (type === 'list') {
    return <AlignJustify className={cn(style, className)} />;
  } else if (type === 'grid') {
    return <LayoutGrid className={cn(style, className)} />;
  } else if (type === 'trash') {
    return <Trash2 className={cn(style, className)} />;
  } else if (type === 'send') {
    return <FiSend className={cn(style, className)} />;
  } else if (type === 'close') {
    return <X className={cn(style, className)} />;
  } else if (type === 'edit') {
    return <PenLine className={cn(style, className)} />;
  } else if (type === 'import') {
    return <FolderInput className={cn(style, className)} />;
  } else if (type === 'arrow-up') {
    return <ChevronUp className={cn(style, className)} />;
  } else if (type === 'left-arrow') {
    return <ArrowLeft className={cn(style, className)} />;
  } else if (type === 'right-arrow') {
    return <ArrowRight className={cn(style, className)} />;
  } else if (type === 'chevron-left') {
    return <ChevronLeft className={cn(style, className)} />;
  } else if (type === 'chevron-right') {
    return <ChevronRight className={cn(style, className)} />;
  } else if (type === 'share') {
    return <Share2 className={cn(style, className)} />;
  } else if (type === 'like-empty') {
    return <AiOutlineLike className={cn(style, className)} />;
  } else if (type === 'like-filled') {
    return <BiSolidLike className={cn(style, className)} />;
  } else if (type === 'bookmark') {
    return <Bookmark className={cn(style, className)} />;
  } else if (type === 'eye') {
    return <Eye className={cn(style, className)} />;
  } else if (type === 'eye-off') {
    return <EyeOff className={cn(style, className)} />;
  }
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: Icons;
  iconPosition?: 'left' | 'right';
  iconStyles?: string;
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant,
      size,
      theme,
      asChild = false,
      loading = false,
      icon = '',
      iconPosition = 'left',
      iconStyles = '',
      disabled = false,
      ...props
    },
    ref
  ) => {
    const mainStyling = buttonVariants({ variant, size, theme, className });
    const iconPositionStyling =
      icon && iconPosition === 'right' ? 'flex-row-reverse' : '';

    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(mainStyling, iconPositionStyling)}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? (
          <Loader2
            className={cn('h-4 w-4 animate-spin', !!children ? 'mr-2' : '')}
          />
        ) : null}
        {!loading && !!icon ? getIcon(icon, iconStyles) : null}
        {children}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
