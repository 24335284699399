import IconHistoric from '../../public/images/icon-historic.svg';
import { X } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from 'components/ui/button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

interface PropTypes {
  className?: string;
  showLog: boolean;
  setShowLog: Dispatch<SetStateAction<boolean>>;
  prevValue?: any;
}

export default function ChangesLog({
  showLog,
  setShowLog,
  prevValue,
  className = ''
}: PropTypes) {
  const { t } = useTranslation();

  return (
    <Popover open={showLog} onOpenChange={setShowLog}>
      <PopoverTrigger
        asChild
        className={className}
        onClick={(e) => e.stopPropagation()}
      >
        <Button variant="ghost" className="hover:bg-transparent p-0 h-min">
          <IconHistoric />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="bg-[#d4e1e8] min-w-[12rem] max-w-[30rem] px-4 py-2"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col gap-0 text-[#53565a]">
          <div className="flex justify-between items-center">
            <div className="text-base font-normal">{t('prev_val')}</div>
            <X
              className="w-4 h-4 p-0 cursor-pointer"
              onClick={() => setShowLog(!showLog)}
            />
          </div>
          <div className="text-sm font-extralight">{prevValue}</div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
