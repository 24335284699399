import HybridTooltip from 'components/ui/hybrid-tooltip';
import ModerationButtons from '../ModerationButtons';
import SelectLog from '../SelectLog';
import styles from './style.module.scss';
import SelectMultiLog from '../SelectMultiLog';
import { useEffect, useState } from 'react';

export default function CustomSelect({
  id,
  value,
  label = '',
  instanceId = undefined,
  prevValue = null,
  data = [],
  onChange = (e) => {},
  onChangeApprove = (id, type) => {},
  wasChanged = false,
  placeholder = '',
  required = false,
  hasNote = false,
  disabled = false,
  isMultiple = true,
  approve = false,
  approved = false,
  changed = false,
  dismiss = false,
  fullWidth = false,
  onInputChange = () => {},
  icon = false,
  icon_content = null,
  noOptionsMessage = 'No Options',
  isLoading = false,
  customStyles: customStyling = {},
  formatOptionLabel = undefined,
  broadcom_fill = false,
  clearable = false,
  onError,
  onClear = () => {},
  onMenuOpen = () => {},
  onMenuClose = () => {},
  isSearchable = true,
  hasColumns = false,
  customHeight = '54px',
  menuPlacement = 'auto',
  customMenuHeight = '',
  inErrorTags = [],
  customMaxHeight = null,
  menuPortalTarget,
  checkErrorOnFirstRender = false,
  maxValues,
  hasCustomError = false,
  customErrorMessage = ''
}: {
  id: string;
  value: any;
  label?: string;
  instanceId?: any;
  prevValue?: any;
  data?: any[];
  onChange: (e) => void;
  onChangeApprove?: (id, type) => void;
  wasChanged?: boolean;
  placeholder?: string;
  required?: boolean;
  hasNote?: any;
  disabled?: boolean;
  isMultiple?: boolean;
  approve?: boolean;
  approved?: boolean;
  changed?: boolean;
  dismiss?: boolean;
  fullWidth?: boolean;
  onInputChange?: () => void;
  icon?: boolean;
  icon_content?: any;
  noOptionsMessage?: string;
  isLoading?: boolean;
  customStyles?: any;
  formatOptionLabel?: any;
  broadcom_fill?: boolean;
  clearable?: boolean;
  onError?: (a: string, b: boolean) => void;
  onClear?: () => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  isSearchable?: boolean;
  hasColumns?: boolean;
  customHeight?: string;
  menuPlacement?: any;
  customMenuHeight?: string;
  inErrorTags?: any[];
  customMaxHeight?: any;
  menuPortalTarget?: any;
  maxValues?: number;
  checkErrorOnFirstRender?: boolean;
  hasCustomError?: boolean;
  customErrorMessage?: string;
}) {
  const [checkError, setCheckError] = useState(checkErrorOnFirstRender);
  const [error, setError] = useState(
    checkErrorOnFirstRender && required && value.length === 0
  );
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleOpenChange = (open) => {
    setIsTooltipOpen(open);
  };

  const openTooltip = () => {
    setIsTooltipOpen(true);
  };

  useEffect(() => {
    if (value?.length > 0) setCheckError(true);
    if (checkError) {
      const hasRequiredFieldError = required && value?.length === 0;
      setError(checkError && (hasRequiredFieldError || hasCustomError));
    }
  }, [value]);

  //if needed change the height (35px) to be received by prop
  const stylesMerge = isMultiple
    ? {
        valueContainer: (provided) =>
          customMaxHeight
            ? {
                ...provided,
                marginRight: '2rem',
                maxHeight: customMaxHeight,
                overflowY: 'auto'
              }
            : {
                ...provided,
                marginRight: '2rem'
              }
      }
    : {
        control: (provided) => ({
          ...provided,
          minHeight: customHeight,
          height: customHeight,
          fontWeight: 'lighter',
          zIndex: 999
        }),
        valueContainer: (provided) =>
          customMaxHeight
            ? {
                ...provided,
                height: customHeight,
                maxHeight: customMaxHeight,
                overflowY: 'auto'
              }
            : {
                ...provided,
                height: customHeight
              },
        indicatorsContainer: (provided) => ({
          ...provided,
          height: customHeight
        }),
        singleValue: (provided) => ({
          ...provided,
          fontWeight: '300'
        })
      };

  const customStyles = {
    ...stylesMerge,
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: (base) =>
      customMenuHeight
        ? {
            ...base,
            height: '200px',
            maxHeight: '200px' // Set your desired fixed height here
          }
        : { ...base, zIndex: 999 },
    menuList: (base) =>
      customMenuHeight
        ? {
            ...base,
            height: '200px',
            maxHeight: '200px',
            zIndex: 999 // Set your desired fixed height here,
          }
        : {
            ...base,
            zIndex: 999 // Set your desired fixed height here,
          },

    dropdownIndicator: (provided) => ({
      ...provided,
      svg: { fill: '#53565A' }
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
      color: '#cccccc',
      '&:hover': {
        color: '#cc092f'
      }
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }),
    multiValue: (provided, state) => {
      return {
        ...provided,
        backgroundColor:
          dismiss &&
          id === 'cert_role' &&
          inErrorTags?.length > 0 &&
          inErrorTags?.includes(state.data.value)
            ? 'rgba(235, 87, 87, 1)'
            : '#E5ECF4',
        color:
          dismiss &&
          id === 'cert_role' &&
          inErrorTags?.length > 0 &&
          inErrorTags?.includes(state.data.value)
            ? '#ffffff'
            : '#1F384C',
        padding: '4px',
        fontSize: '18px',
        fontWeight: 'lighter'
      };
    },
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#1F384C',
      fontWeight: 300,
      fontSize: '16px'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontStyle: 'normal',
      fontWeight: '200',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#53565A'
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      padding: 5,
      fontSize: '5px !important'
    }),
    ...customStyling
  };
  const customStylesReqReview = {
    ...stylesMerge,
    indicatorSeparator: (state) => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: { fill: '#53565A' }
    }),
    clearIndicator: (provided) => ({
      ...provided
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#f2c94c',
      color: 'white',
      padding: '4px',
      fontSize: '18px',
      fontWeight: '300'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white'
    }),
    ...customStyling
  };

  const showLabelSection =
    label ||
    broadcom_fill ||
    required ||
    hasNote ||
    icon ||
    (approve && wasChanged);

  return (
    <div
      className={`custom_select_input ${fullWidth ? styles.full_width : ''}`}
    >
      {showLabelSection && (
        <div className={styles.labels_and_moderation}>
          <label htmlFor={id} className={styles.label}>
            {label}
            {broadcom_fill && <span className={styles.red_req}>*</span>}
            {required && (
              <span
                className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}
              >
                *
              </span>
            )}
            {typeof hasNote === 'object' && Object.keys(hasNote).length > 0 ? (
              <span className={hasNote.inform ? styles.hasNoteInform : ''}>
                {hasNote.text}
              </span>
            ) : (
              hasNote && <span className={styles.hasNote}>{hasNote}</span>
            )}
            {icon && (
              <HybridTooltip
                text={icon_content}
                open={isTooltipOpen}
                onOpenChange={handleOpenChange}
              />
            )}
          </label>
          {approve && wasChanged && (
            <ModerationButtons
              id={id}
              onChangeApprove={onChangeApprove}
              approved={approved}
              dismissed={dismiss}
            />
          )}
        </div>
      )}
      {!isMultiple ? (
        <SelectLog
          id={id}
          disabled={disabled}
          instanceId={instanceId}
          customStyles={wasChanged ? customStylesReqReview : customStyles}
          placeholder={placeholder}
          prevValue={prevValue}
          data={data}
          onChange={onChange}
          value={value}
          wasChanged={wasChanged}
          approved={approved}
          changed={changed}
          dismiss={dismiss}
          onInputChange={onInputChange}
          noOptionsMessage={noOptionsMessage}
          isLoading={isLoading}
          formatOptionLabel={formatOptionLabel}
          clearable={clearable}
          onClear={onClear}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          isSearchable={isSearchable}
          hasColumns={hasColumns}
          customHeight={customHeight}
          menuPlacement={menuPlacement}
          menuPortalTarget={menuPortalTarget}
        />
      ) : (
        <SelectMultiLog
          id={id}
          disabled={disabled}
          instanceId={instanceId}
          customStyles={wasChanged ? customStylesReqReview : customStyles}
          placeholder={placeholder}
          closeMenuOnSelect={false}
          prevValue={prevValue}
          data={data}
          onChange={onChange}
          value={value}
          wasChanged={wasChanged}
          approved={approved}
          changed={changed}
          dismiss={dismiss}
          onMenuOpen={() => {
            if (isFirstRender) {
              openTooltip();
              setIsFirstRender(false);
            }
            onMenuOpen && onMenuOpen();
          }}
          onMenuClose={onMenuClose}
          menuPlacement={menuPlacement}
          maxValues={maxValues}
          onError={onError}
          error={error}
          customErrorMessage={customErrorMessage}
          hasCustomError={hasCustomError}
        />
      )}
    </div>
  );
}
